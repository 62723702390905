import { API_URL } from "../others/constants";
import { useNonAuthFetchJson } from "./useFetch";


export const useSubscribersLimit = () => {

    const { callApi, data, loading, error } = useNonAuthFetchJson<
        {},
        { limit: number }
    >(API_URL + "/subscribersLimit", "GET");

    return { callApi, data, loading, error };
}