import { API_URL } from "../others/constants";
import { Product } from "../others/types";
import { useNonAuthFetchJson } from "./useFetch";


export const useProductInfo = () => {

    const { callApi, data, loading, error } = useNonAuthFetchJson<
        {},
        { products: Array<Product> }
    >(API_URL + "/productInfo", "GET");

    return { callApi, data, loading, error };
}