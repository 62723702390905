import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";

export const ProductIngredientsModal = ({
  open,
  setOpen,
  title,
  ingredients,
}) => {
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Box
          style={{
            position: "absolute",
            top: "40%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          className="bg-gradient-to-r from-lime-600 to-green-600 rounded-md shadow-xl pt-4 p-6 flex justify-center items-center flex-col w-3/5 lg:w-2/5 sm:w-3/5"
        >
          <h1 className="text-center text-white font-bold text-lg ">
            {title}
          </h1>
          <h2 className="text-center text-gray-200 font-bold text-xs pb-2">This is just an example containing some estimates. They are subject to change, but we are commited to maintaining our products healthy, tasty, and affordable</h2>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ color: "white" }}></TableCell>
                  <TableCell sx={{ color: "white" }} align="right">
                    Amount
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ingredients.map((ingredient) => {
                  return (
                    <TableRow key={ingredient.name}>
                      <TableCell sx={{ color: "white" }}>
                        {ingredient.name}
                      </TableCell>
                      {ingredient.amount !== 0 && (
                        <TableCell sx={{ color: "white" }} align="right">
                          {ingredient.amount}
                          {ingredient.unit}
                        </TableCell>
                      )}
                      {ingredient.amount === 0 && (
                        <TableCell sx={{ color: "white" }} align="right">
                          X
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Modal>
  );
};
