import { Popover } from "@headlessui/react";

import logo_no_text from "../../static/logoNoWriting.jpg";
import "./styles.css";

var prevScrollpos = window.pageYOffset;
window.onscroll = function () {
  var currentScrollPos = window.pageYOffset;
  if (
    prevScrollpos < currentScrollPos &&
    currentScrollPos > 200 &&
    document.getElementById("sticky-nav")
  ) {
    document.getElementById("sticky-nav").style.opacity = 0;
  } else if (
    prevScrollpos > currentScrollPos &&
    document.getElementById("sticky-nav")
  ) {
    document.getElementById("sticky-nav").removeAttribute("style");
  }
  prevScrollpos = currentScrollPos;
};

const HeaderLanding = () => {
  return (
    <header id="sticky-nav" className="sticky-nav">
      <Popover className="relative bg-white">
        <div className="mx-auto flex max-w-7xl items-center justify-between p-6  md:space-x-10 lg:px-8">
          <div className="flex justify-start lg:w-0 md:flex-1">
            <a href="/">
              <span className="sr-only">Dot Jar</span>
              <img className="h-12" src={logo_no_text} alt="" />
            </a>
          </div>

          <Popover.Group as="nav" className="hidden space-x-10 md:flex">
            <a
              href="/#pricing"
              className="text-base font-medium text-gray-500 hover:text-gray-900"
            >
              Pricing
            </a>

            <a
              href="/#mealPlans"
              className="text-base font-medium text-gray-500 hover:text-gray-900"
            >
              Meal Plans
            </a>
            <a
              href="#delivery"
              className="text-base font-medium text-gray-500 hover:text-gray-900"
            >
              Delivery
            </a>
            <a
              href="#newsletter"
              className="text-base font-medium text-gray-500 hover:text-gray-900"
            >
              Newsletter
            </a>
            <a
              href="mailto:info@dot-jar.com"
              className="text-base font-medium text-gray-500 hover:text-gray-900"
            >
              Contact us
            </a>
          </Popover.Group>
          <div className="items-center justify-end md:flex md:flex-1 lg:w-0">
            <a
              href="#mealPlans"
              className="ml-8 inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-gradient-to-r from-green-600 to-lime-600 bg-origin-border px-4 py-2 text-base font-medium text-white shadow-sm hover:from-green-700 hover:to-lime-700"
            >
              Subscribe!
            </a>
          </div>
        </div>
      </Popover>
    </header>
  );
};
export default HeaderLanding;
