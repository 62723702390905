import { Outlet } from "react-router-dom";
import HeaderProduct from "../components/headerProduct";
import { Banner } from "../components/banner";
import FooterSimple from "../components/footerSimple";
const ProductLayout = () => {
  return (
    <>
      <HeaderProduct />
      <Banner message={"Launch: Sep 2024"} sx={{}}/>
      <div style={{ paddingTop: 0 }}>
        <Outlet />
      </div>
      <FooterSimple />
    </>
  );
};

export default ProductLayout;
