import { CircularProgress } from "@mui/material";
import { useEffect } from "react";

export const SuccessPage = () => {

  useEffect(() => {
    // callApi({});
  }, []);
  useEffect(() => {
    window.location.href = "/?success=1"
  }, [])
  return (
    <div className="h-full w-full flex items-center justify-center p-20">
      <CircularProgress />
    </div>
  );
};
