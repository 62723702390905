
import { API_URL } from "../others/constants";
import { useNonAuthFetchJson } from "./useFetch";

export const useSubscribersCount = () => {
    const { callApi, data, loading, error } = useNonAuthFetchJson<
        {},
        { count: number }
    >(API_URL + "/subscribersCount", "GET");

    return { callApi, data, loading, error };
};
