import { API_URL } from "../others/constants";
import { SubscribeMailInput } from "../others/interfaces";
import { useNonAuthFetchJson } from "./useFetch";

export const useSubscribeMail = () => {
  const { callApi, data, loading, error } = useNonAuthFetchJson<
    SubscribeMailInput,
    {}
  >(API_URL + "/subscribeMail", "POST");

  return { callApi, data, loading, error };
};
