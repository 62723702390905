import { useEffect } from "react";
import { useSubscribersCount } from "./useSubscribersCount";
import { useSubscribersLimit } from "./useSubscribersLimit";
import { useWeeklySubscriptionPrice } from "./useWeeklySubscriptionPrice";

export const useSubscribeInfo = () => {
    const {
        callApi: callSubscribersCount,
        data: subcribersCountData,
        error: subscribersCountError,
        loading: subscribersCountLoading,
    } = useSubscribersCount();

    const {
        callApi: callSubscribersLimit,
        data: SubscribersLimitData,
        error: SubscribersLimitError,
        loading: SubscribersLimitLoading,
    } = useSubscribersLimit();

    const {
        callApi: callWeeklySubscriptionPrice,
        data: weeklySubscriptionPriceData,
        error: weeklySubscriptionPriceError,
        loading: weeklySubscriptionPriceLoading,
    } = useWeeklySubscriptionPrice();

    useEffect(() => {
        callSubscribersCount({});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        callSubscribersLimit({});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        callWeeklySubscriptionPrice({});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const subscribersCount =
        subcribersCountData && !subscribersCountError && !subscribersCountLoading
            ? subcribersCountData.count
            : 18;
    const subscribersLimit =
        SubscribersLimitData && !SubscribersLimitError && !SubscribersLimitLoading
            ? SubscribersLimitData.limit
            : 68;

    const weeklySusbcriptionPrice =
        weeklySubscriptionPriceData &&
            !weeklySubscriptionPriceError &&
            !weeklySubscriptionPriceLoading
            ? weeklySubscriptionPriceData.price
            : 69;

    return { subscribersCount, subscribersLimit, weeklySusbcriptionPrice }
}