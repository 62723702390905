import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useContext, useState } from "react";
import { Context } from "../context";

export type CallApiProps<T> = {
  data?: T;
};

export const useNonAuthFetchJson = <T, V>(url: string, method: "POST"|"GET") => {
  const [data, setData] = useState<V>({} as V);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const callApi = useCallback(
    async ({ data }: CallApiProps<T>) => {
      setLoading(true);

      await fetch(url, {
        method: method ?? "POST",
        body: JSON.stringify(data),
        headers: {},
      })
        .then((response) => {
          if (!response.ok) throw new Error("Error fetching");
          setError(false);
          return response.json();
        })
        .then((data) => setData(data))
        .catch(() => setError(true))
        .finally(() => setLoading(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return { callApi, data, loading, error };
};

export const useFetchJson = <T, V>(url: string) => {
  const [data, setData] = useState<V>({} as V);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const { getAccessTokenWithPopup, isAuthenticated, getAccessTokenSilently } =
    useAuth0();

  const globalState = useContext(Context);

  const callApi = useCallback(
    async ({ data }: CallApiProps<T>) => {
      setLoading(true);
      let bearerFunction;
      if (!isAuthenticated) {
        bearerFunction = getAccessTokenWithPopup;
      } else {
        bearerFunction = getAccessTokenSilently;
      }

      let bearer = "";
      try {
        bearer = await bearerFunction();
        globalState.updateState({ ...globalState, authError: false });
      } catch (error) {
        setError(true);
        setLoading(false);
        console.error(error);
        globalState.updateState({ ...globalState, authError: true });
        return;
      }

      await fetch(url, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          Authorization: `Bearer ${bearer}`,
        },
      })
        .then((response) => {
          if (!response.ok) throw new Error("Error fetching");
          setError(false);
          return response.json();
        })
        .then((data) => setData(data))
        .catch(() => setError(true))
        .finally(() => setLoading(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAuthenticated]
  );

  return { callApi, data, loading, error };
};
