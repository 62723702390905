import React, { useState } from 'react'
import { AppState, Context } from './context'

interface Props {
	children: React.ReactNode
}

/**
 * The main context provider
 */
export const ContextProvider: React.FunctionComponent<Props> = (
	props: Props
): JSX.Element => {
	const [state, setState] = useState({})
	const updateState = (newState: Partial<AppState>) => {
		setState({ ...state, ...newState })
	}

	return (
		<Context.Provider value={{ ...state, updateState }}>
			{props.children}
		</Context.Provider>
	)
}
