import { API_URL } from "../others/constants";
import { useNonAuthFetchJson } from "./useFetch";

export type CheckoutSessionProps = {
    mealPlan: "Standard"|"Keto"|"Vegetarian",
    successUrl: string,
    cancelUrl: string
}

export const useBuy = () => {

    const { callApi, data, loading, error } = useNonAuthFetchJson<
        CheckoutSessionProps,
        { redirectUrl: string }
    >(API_URL + "/buy", "POST");

    return { callApi, data, loading, error };
}