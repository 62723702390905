import CountUp from "react-countup";
import ReactVisibilitySensor from "react-visibility-sensor";
import { Link, useSearchParams } from "react-router-dom";
import headshot from "../../static/headshot.avif";
import workingOnLaptops from "../../static/workingOnLaptop.avif";
import amazon_icon from "../../static/amazon_icon.svg";
import arendt_icon from "../../static/arendt_icon.png";
import bnl_icon from "../../static/bnl_icon.png";
import ey_icon from "../../static/ey_icon.png";
import kpmg_icon from "../../static/kpmg_icon.png";

import {
  Battery100Icon,
  ClockIcon,
  RocketLaunchIcon,
  CheckIcon,
  QuestionMarkCircleIcon,
  XMarkIcon,
  BoltIcon,
} from "@heroicons/react/24/solid";
import { classNames } from "../../others/utils";
import { EmailForm } from "../../components/emailForm";
import jar2 from "../../static/jarPictures/dotjar2.jpeg";
import jar5 from "../../static/jarPictures/dotjar5.jpeg";
import jar8 from "../../static/jarPictures/dotjar8.jpeg";
import { ThankYouModal } from "../../components/thankYouModal";
import { useContext, useEffect, useState } from "react";

import { CustomLinearProgress } from "../../components/product";
import { useSubscribeInfo } from "../../hooks/useSubscribeInfo";
import { Context } from "../../context";
import { Tooltip } from "@mui/material";

const plansTiers = [
  {
    name: "Vegetarian",
    description: "A meal to easily stay on your vegetarian diet",
    features: [
      "All the micronutrients you need to thrive",
      "An optimal split of macro nutrients for human health",
      "No animal food",
      "A different meal every day",
      "Non picked up meals can be redeemed the next week",
    ],
    imgUrl: "https://iili.io/J6GzaUu.jpg",
    comingSoon: true,
  },
  {
    name: "Standard",
    description: "A meal for someone with no dietary preferences",
    features: [
      "All the micronutrients you need to thrive",
      "An optimal split of macro nutrients for human health",
      "A variety of foods to maximise enjoyment",
      "A different meal every day",
      "Non picked up meals can be redeemed the next week",
    ],
    imgUrl: "https://iili.io/J6Gxj9f.md.jpg",
    comingSoon: false,
  },
  {
    name: "Keto",
    description: "A zero carb meal to achieve your goals",
    features: [
      "All the micronutrients you need to thrive",
      "An optimal split of macro nutrients for human health",
      "Almost zero carbs",
      "A different meal every day",
      "Non picked up meals can be redeemed the next week",
    ],
    imgUrl: "https://iili.io/J6GzIDl.jpg",
    comingSoon: true,
  },
];
export default function LandingPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const globalState = useContext(Context);
  const [successModalOpen, setSuccessModalOpen] = useState(false);

  const [counterVisible2, setCounterVisible2] = useState(false);

  const { subscribersCount, subscribersLimit, weeklySusbcriptionPrice } =
    useSubscribeInfo();

  const calculatedSubscriptionsLeft = subscribersLimit - subscribersCount;

  const pricing = {
    title: "Jar Drop 0",
    description: "The best financial services for your thriving business.",
    priceWeekly: weeklySusbcriptionPrice,
    mainFeatures: [
      {
        id: 1,
        value: `Only available for the first ${subscribersLimit} subscriptions`,
        icon: BoltIcon,
      },
      {
        id: 2,
        value: "Launching in September 2024",
        icon: RocketLaunchIcon,
      },
      {
        id: 3,
        value: "Working from home? Pick up your meal next week",
        icon: QuestionMarkCircleIcon,
      },
    ],
  };

  useEffect(() => {
    if (globalState.mailSubscribed) {
      setInterval(() => {
        globalState.updateState({ ...globalState, mailSubscribed: false });
      }, 5000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalState.mailSubscribed]);

  useEffect(() => {
    if (searchParams.get("success")) {
      setSuccessModalOpen(true);
      setSearchParams({});

      setInterval(() => {
        setSuccessModalOpen(false);
      }, 5000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="bg-white scroll-smooth">
      <ThankYouModal
        open={globalState.mailSubscribed}
        setOpen={(a) => {
          globalState.updateState({ ...globalState, mailSubscribed: a });
        }}
        title={"Thank you for subscribing"}
        description={
          "We will keep you updated on the best Lunch Solutions in Luxembourg"
        }
      />

      <ThankYouModal
        open={successModalOpen}
        setOpen={setSuccessModalOpen}
        title={"Thank you for Buying you first week!"}
        description={"We are launching in September of 2024"}
      />
      <main>
        {/* Hero section */}
        <div className="relative">
          <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100" />
          <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="relative shadow-xl sm:overflow-hidden sm:rounded-2xl">
              <div className="absolute inset-0">
                <img
                  className="h-full w-full object-cover"
                  src={workingOnLaptops}
                  alt="People working on laptops"
                />
                <div className="absolute inset-0 bg-gradient-to-r from-green-800 to-lime-800 mix-blend-multiply" />
              </div>
              <div className="relative py-16 px-6 sm:py-24 lg:py-32 lg:px-8">
                <h1 className="text-center text-4xl font-bold tracking-tight sm:text-5xl lg:text-6xl">
                  <span className="block text-white">
                    Healthy, Tasty, Affordable
                  </span>
                  <span className="block text-green-300">Lunch in a Jar</span>
                </h1>
                <p className="mx-auto mt-6 max-w-lg text-center text-xl text-green-100 sm:max-w-3xl">
                  Lunch in Luxembourg has never been easier. Delivered to your
                  office every workday. Subscribe or opt out weekly.
                </p>
                <div className="mx-auto mt-10 max-w-sm sm:flex sm:max-w-none sm:justify-center">
                  <div className="space-y-4 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5 sm:space-y-0">
                    <a
                      href="#mealPlans"
                      className="flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-3 text-base font-medium text-lime-700 shadow-sm hover:bg-lime-50 sm:px-8"
                    >
                      Buy First Week
                    </a>
                    <a
                      href="#learnmore"
                      className="flex items-center justify-center rounded-md border border-transparent bg-lime-500 bg-opacity-60 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-opacity-70 sm:px-8"
                    >
                      Learn More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Logo Cloud */}
        <div id="delivery" className="bg-gray-100">
          <div className="mx-auto max-w-7xl py-16 px-6 lg:px-8">
            <p className="text-center text-base font-semibold text-gray-500">
              Delivered next to these offices:
            </p>
            <div className="mt-6 grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-5">
              <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                <img className="h-12" src={amazon_icon} alt="Tuple" />
              </div>
              <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                <img className="h-12" src={ey_icon} alt="Mirage" />
              </div>
              <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                <img className="h-12" src={kpmg_icon} alt="StaticKit" />
              </div>
              <div className="col-span-1 flex justify-center md:col-span-2 md:col-start-2 lg:col-span-1">
                <img className="h-12" src={bnl_icon} alt="Transistor" />
              </div>
              <div className="col-span-2 flex justify-center md:col-span-2 md:col-start-4 lg:col-span-1">
                <img className="h-12" src={arendt_icon} alt="Workcation" />
              </div>
            </div>
          </div>
        </div>

        {/* Alternating Feature Sections */}
        <div className="relative overflow-hidden pt-12 pb-32" id="learnmore">
          <div
            aria-hidden="true"
            className="absolute inset-x-0 top-0 h-48 bg-gradient-to-b from-gray-100"
          />
          <div className="relative">
            <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8">
              <div className="mx-auto max-w-xl px-6 lg:mx-0 lg:max-w-none lg:py-16 lg:px-0">
                <div className="flex flex-row items-center justify-start gap-4 mt-6">
                  <div className="flex h-12 w-12 items-center justify-center rounded-md border border-green-600 bg-gradient-to-r from-lime-100 to-lime-300">
                    <ClockIcon
                      className="h-6 w-6 text-green-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div>
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                      Save time
                    </h2>
                  </div>
                </div>
                <p className="mt-4 text-lg text-gray-500">
                  We know how busy you are, don't spend your precious time
                  preparing lunch, let us do it!
                </p>
                <div className="mt-6">
                  <a
                    href="#pricing"
                    className="inline-flex rounded-md border border-transparent bg-gradient-to-r from-green-600 to-lime-600 bg-origin-border px-4 py-2 text-base font-medium text-white shadow-sm hover:from-green-700 hover:to-lime-700"
                  >
                    Pricing
                  </a>
                </div>
                <div className="mt-8 border-t border-gray-200 pt-6">
                  <blockquote>
                    <div>
                      <p className="text-base text-gray-500">
                        &ldquo;It has saved me so much time. Life
                        Changing.&rdquo;
                      </p>
                    </div>
                    <footer className="mt-3">
                      <div className="flex items-center space-x-3">
                        <div className="flex-shrink-0">
                          <img
                            className="h-6 w-6 rounded-full"
                            src={headshot}
                            alt=""
                          />
                        </div>
                        <div className="text-base font-medium text-gray-700">
                          Marcia Hill, Software Engineer
                        </div>
                      </div>
                    </footer>
                  </blockquote>
                </div>
              </div>
              <div className="mt-12 sm:mt-16 lg:mt-0">
                <div className="flex justify-center pl-6 md:-mr-16 lg:relative lg:m-0 lg:h-full lg:px-0">
                  <img
                    className="w-6/12 rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                    src={jar2}
                    alt="Inbox user interface"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="mt-24">
            <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8">
              <div className="mx-auto max-w-xl px-6 lg:mx-0 lg:max-w-none lg:py-16 lg:px-0">
                <div className="flex flex-row items-center justify-start gap-4 mt-6">
                  <div className="flex h-12 w-12 items-center justify-center rounded-md border border-green-600 bg-gradient-to-r from-lime-100 to-lime-300">
                    <RocketLaunchIcon
                      className="h-6 w-6 text-green-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                      Improve your Health
                    </h2>
                  </div>
                </div>
                <p className="mt-4 text-lg text-gray-500">
                  DotJar cares about your Health, and provides you a balanced
                  meal plan, allowing you to achieve your goals.
                </p>
                <div className="mt-6">
                  <a
                    href="#mealPlans"
                    className="inline-flex rounded-md border border-transparent bg-gradient-to-r from-green-600 to-lime-600 bg-origin-border px-4 py-2 text-base font-medium text-white shadow-sm hover:from-green-700 hover:to-lime-700"
                  >
                    Meal Plans
                  </a>
                </div>
              </div>
              <div className="mt-12 sm:mt-16 lg:col-start-1 lg:mt-0">
                <div className="flex justify-center pr-6 md:-ml-16 lg:relative lg:m-0 lg:h-full lg:px-0">
                  <img
                    className="w-6/12  rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                    src={jar8}
                    alt="Customer profile user interface"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-24">
            <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8">
              <div className="mx-auto max-w-xl px-6 lg:mx-0 lg:max-w-none lg:py-16 lg:px-0">
                <div className="flex flex-row items-center justify-start gap-4 mt-6">
                  <div className="flex h-12 w-12 items-center justify-center rounded-md border border-green-600 bg-gradient-to-r from-lime-100 to-lime-300">
                    <Battery100Icon
                      className="h-6 w-6 text-green-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div>
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                      Stay Energized
                    </h2>
                  </div>
                </div>
                <p className="mt-4 text-lg text-gray-500">
                  Our meals are designed to help you focus on your work.
                </p>
                <div className="mt-6">
                  <a
                    href="#mealPlans"
                    className="inline-flex rounded-md border border-transparent bg-gradient-to-r from-green-600 to-lime-600 bg-origin-border px-4 py-2 text-base font-medium text-white shadow-sm hover:from-green-700 hover:to-lime-700"
                  >
                    Subscribe
                  </a>
                </div>
              </div>
              <div className="mt-12 sm:mt-16 lg:mt-0">
                <div className="flex justify-center pl-6 md:-mr-16 lg:relative lg:m-0 lg:h-full lg:px-0">
                  <img
                    className="w-6/12  rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                    src={jar5}
                    alt="Inbox user interface"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="mealPlans" className="relative bg-green-600 pb-10">
          <div className="mx-auto max-w-7xl px-6 pt-24 lg:px-8">
            <div className="sm:align-center sm:flex sm:flex-col">
              <h1 className="text-5xl font-bold tracking-tight text-white sm:text-center">
                Meal Plans
              </h1>
              <p className="mt-5 text-xl text-gray-100 sm:text-center">
                Pick your plan, will bring you something different everyday.
              </p>
            </div>
            <div className="mt-12 space-y-4 sm:mt-16 sm:grid sm:grid-cols-1 sm:gap-6 sm:space-y-0 lg:mx-auto lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-3">
              {plansTiers.map((tier) => (
                <div
                  key={tier.name}
                  className="divide-y divide-gray-200 bg-white ring-2 ring-green-600 shadow-md rounded-lg"
                >
                  <div className="p-6">
                    <h2 className="text-lg font-medium leading-6 text-gray-900">
                      {tier.name}
                    </h2>
                    <p className="mt-4 text-sm text-gray-500">
                      {tier.description}
                    </p>
                    <Tooltip title={tier.comingSoon ? "Coming soon" : ""}>
                      <Link
                        to={tier.comingSoon ? "" : `/product/${tier.name}`}
                        className={`inline-flex w-full mt-8 px-8 justify-center items-center rounded-md border border-transparent bg-green-600 py-2 text-center text-sm font-semibold text-white hover:bg-green-700 ${
                          tier.comingSoon ? "opacity-50 cursor-not-allowed" : ""
                        }`}
                      >
                        {tier.comingSoon && <XMarkIcon className="w-6 h-6" />}
                        <p className={`block w-full`}>Buy {tier.name}</p>
                      </Link>
                    </Tooltip>
                  </div>
                  <div>
                    <img
                      src={tier.imgUrl}
                      alt={`An example of a jar containing the ${tier.name} meal plan`}
                    />
                  </div>
                  <div className="px-6 pt-6 pb-8">
                    <h3 className="text-sm font-medium text-gray-900">
                      Always Contains
                    </h3>
                    <ul className="mt-6 space-y-4">
                      {tier.features.map((feature) => (
                        <li key={feature} className="flex space-x-3">
                          <CheckIcon
                            className="h-5 w-5 flex-shrink-0 text-green-500"
                            aria-hidden="true"
                          />
                          <span className="text-sm text-gray-500">
                            {feature}
                          </span>
                        </li>
                      ))}

                      <li className="flex items-center border-t-2 py-3">
                        <RocketLaunchIcon
                          className="h-5 w-5 flex-shrink-0 text-green-500"
                          aria-hidden="true"
                        />
                        <span
                          className={classNames(
                            "text-gray-600",
                            "ml-4 text-sm font-bold"
                          )}
                        >
                          Launching in Sep 2024
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div id="pricing">
          <div className="relative bg-gradient-to-b from-white to-gray-50 pb-10">
            {/* Overlapping background */}
            <div
              aria-hidden="true"
              className="absolute bottom-0 hidden h-6 w-full bg-gray-50 lg:block"
            />

            <div className="relative mx-auto max-w-2xl px-6 pt-16 text-center sm:pt-32 lg:max-w-7xl lg:px-8">
              <h1 className="text-4xl font-bold tracking-tight text-green-600 sm:text-6xl">
                <span className="block lg:inline">
                  Simple pricing, no commitment
                </span>
              </h1>
              <p className="mt-4 text-xl text-lime-600">
                Pay weekly, cancel any time
              </p>
            </div>

            <h2 className="sr-only">Plans</h2>

            {/* Cards */}
            <div className="relative mx-auto mt-8 max-w-2xl px-6 pb-8 sm:mt-12 lg:max-w-7xl lg:px-8 lg:pb-0">
              {/* Decorative background */}
              <div
                aria-hidden="true"
                className="absolute inset-0 top-0 bottom-6 left-8 right-8 rounded-lg rounded-tr-lg bg-green-600 block "
              />

              <div className="relative space-y-6 lg:space-y-0 pb-16 px-8">
                <div
                  key={pricing.title}
                  className={classNames(
                    "bg-white ring-2 ring-green-600 shadow-md",
                    "pt-6 px-6 pb-3 rounded-lg lg:px-8 lg:pt-12"
                  )}
                >
                  <div>
                    <h3
                      className={classNames(
                        "text-green-600",
                        "text-base font-semibold"
                      )}
                    >
                      {pricing.title}
                    </h3>
                    <div className="flex flex-col items-start sm:flex-row sm:items-center sm:justify-between lg:flex-col lg:items-start">
                      <div className="mt-3 flex items-center">
                        <p
                          className={classNames(
                            "text-green-600",
                            "text-4xl font-bold tracking-tight"
                          )}
                        >
                          €{pricing.priceWeekly}
                        </p>
                        <div className="ml-4">
                          <p className={classNames("text-gray-700", "text-sm")}>
                            EUR / week
                          </p>
                          <p className={classNames("text-gray-500", "text-sm")}>
                            Cancel any week
                          </p>
                        </div>
                      </div>
                      <a
                        href={"#mealPlans"}
                        className={classNames(
                          "bg-green-600 text-white hover:bg-green-700",
                          "disabled",
                          "mt-6 w-full inline-block py-2 px-8 border border-transparent rounded-md shadow-sm text-center text-sm font-medium sm:mt-0 sm:w-auto lg:mt-6 lg:w-full"
                        )}
                      >
                        {`Choose your plan`}
                      </a>
                    </div>
                  </div>
                  <h4 className="sr-only">Features</h4>
                  <ul
                    className={classNames(
                      "border-gray-200 divide-gray-200",
                      "mt-7 border-t divide-y lg:border-t-0"
                    )}
                  >
                    {pricing.mainFeatures.map((mainFeature) => (
                      <li
                        key={mainFeature.id}
                        className="flex items-center py-3"
                      >
                        {mainFeature.icon && (
                          <mainFeature.icon
                            className={classNames(
                              "text-green-500",
                              "w-5 h-5 flex-shrink-0"
                            )}
                            aria-hidden="true"
                          />
                        )}

                        <span
                          className={classNames(
                            "text-gray-600",
                            "ml-4 text-sm font-medium"
                          )}
                        >
                          {mainFeature.value}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
                <CustomLinearProgress
                  value={(100 * subscribersCount) / subscribersLimit}
                  sx={{ height: 5 }}
                  color={"success"}
                />
                <ReactVisibilitySensor
                  onChange={(visible) => {
                    if (visible) {
                      setCounterVisible2(true);
                    }
                  }}
                  delayedCall
                >
                  <div className="flex flex-row items-center justify-center text-white">
                    <h1>Only&nbsp;</h1>
                    <CountUp
                      start={0}
                      end={counterVisible2 ? calculatedSubscriptionsLeft : 0}
                      duration={3}
                      delay={1}
                    />
                    <h1 className="text-center text-white">&nbsp;left</h1>
                  </div>
                </ReactVisibilitySensor>
              </div>
            </div>
          </div>
        </div>

        {/* Stats section */}
        <div id="newsletter" className="relative bg-gray-900">
          <div className="absolute inset-x-0 bottom-0 h-80 xl:top-0 xl:h-full">
            <div className="h-full w-full xl:grid xl:grid-cols-2">
              <div className="h-full xl:relative xl:col-start-2">
                <img
                  className="h-full w-full object-cover opacity-25 xl:absolute xl:inset-0 "
                  src={workingOnLaptops}
                  alt="People working on laptops"
                />
                <div
                  aria-hidden="true"
                  className="absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-gray-900  xl:inset-y-0 xl:left-0 xl:h-full xl:w-32 xl:bg-gradient-to-r"
                />
                <div
                  dir="rtl"
                  aria-hidden="true"
                  className="absolute opacity-30 top-0  bg-gradient-to-b from-gray-900 to-green-500 xl:inset-y-0 left-0 w-full xl:h-full xl:bg-gradient-to-r"
                />
              </div>
            </div>
          </div>
          <div className="mx-auto max-w-4xl px-6 lg:max-w-7xl lg:px-8 xl:grid xl:grid-flow-col-dense xl:grid-cols-2 xl:gap-x-8">
            <div className="relative pt-12 pb-64 sm:pt-24 sm:pb-64 xl:col-start-1 xl:pb-24">
              <h2 className="text-base font-semibold">
                <span className="text-white bg-clip-text text-transparent">
                  Sign up to be updated on our progress
                </span>
              </h2>
              <p className="text-green-400 bg-clip-text mt-3 text-2xl font-bold tracking-tight ">
                Enter your email:
              </p>
              <p className="mt-5  text-gray-300 text-base"></p>

              <div className="pt-4">
                <EmailForm />
              </div>
            </div>
          </div>
        </div>
        {/* CTA Section */}
        <div className="bg-white">
          <div className="mx-auto max-w-4xl py-16 px-6 sm:py-24 lg:flex lg:max-w-7xl lg:items-center lg:justify-between lg:px-8">
            <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              <span className="block">Unhappy with your office lunch?</span>
              <span className="-mb-1 block bg-gradient-to-r from-green-600 to-lime-600 bg-clip-text pb-1 text-transparent">
                Subscribe now!
              </span>
            </h2>
            <div className="mt-6 space-y-4 sm:flex sm:space-y-0 sm:space-x-5">
              <a
                href="#mealPlans"
                className="flex w-full items-center justify-center rounded-md border border-transparent bg-gradient-to-r from-green-600 to-lime-600 bg-origin-border px-4 py-3 text-base font-medium text-white shadow-sm hover:from-green-700 hover:to-lime-700"
              >
                Meal Plans
              </a>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
