import CountUp from "react-countup";
import ReactVisibilitySensor from "react-visibility-sensor";
import { useEffect, useState } from "react";
import { CheckIcon, QuestionMarkCircleIcon } from "@heroicons/react/20/solid";
import { ShieldCheckIcon } from "@heroicons/react/24/outline";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { ProductIngredientsModal } from "../productIngredientsModal";
import { useBuy } from "../../hooks/useBuy";

export function CustomLinearProgress({ value, sx, color }) {
  return (
    <Box sx={{ width: "100%" }} display="flex" alignItems="center" p={3}>
      <Box width="100%" mr={3}>
        <LinearProgress
          variant="determinate"
          color={color}
          sx={sx}
          value={value}
        />
      </Box>
    </Box>
  );
}

export default function Product({ product, id, price, limit, count }) {
  const breadcrumbs = [
    { id: 1, name: "Subscription", href: "#" },
    { id: 2, name: "Weekly", href: "#" },
  ];
  const [ingredientsModal, setIngredientsModal] = useState(false);
  const [macroModal, setMacroModal] = useState(false);
  const [counterVisible, setCounterVisible] = useState(false);

  const { callApi: callStripe, data: stripeData, error, loading } = useBuy();
  const calculatedSubscriptionsLeft = limit - count;

  useEffect(() => {
    if (stripeData && stripeData.redirectUrl) {
      window.location.href = stripeData.redirectUrl;
    }
  }, [stripeData]);

  const checkoutSessionProps = {
    mealPlan: id,
    successUrl: "https://dot-jar.com/success",
    cancelUrl: window.location.href,
  };

  console.log(checkoutSessionProps);
  console.log(stripeData, error, loading);

  const macros = Object.keys(product.macronutrients).map((macro) => {
    return {
      name: macro,
      amount: product.macronutrients[macro],
      unit: "g",
    };
  });

  return (
    <div className="bg-white">
      {product.exampleOfMeal && product.exampleOfMeal.ingredients && (
        <ProductIngredientsModal
          open={ingredientsModal}
          setOpen={setIngredientsModal}
          title="Ingredients from sample meal:"
          ingredients={product.exampleOfMeal.ingredients}
        />
      )}
      {macros && (
        <ProductIngredientsModal
          open={macroModal}
          setOpen={setMacroModal}
          title="Macronutrients from sample meal:"
          ingredients={macros}
        />
      )}

      <div className="mx-auto max-w-2xl py-16 px-4 sm:py-24 sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
        {/* Product details */}
        <div className="lg:max-w-lg lg:self-end">
          <nav aria-label="Breadcrumb">
            <ol className="flex items-center space-x-2">
              {breadcrumbs.map((breadcrumb, breadcrumbIdx) => (
                <li key={breadcrumb.id}>
                  <div className="flex items-center text-sm">
                    <div className="font-medium text-gray-500 ">
                      {breadcrumb.name}
                    </div>
                    {breadcrumbIdx !== breadcrumbs.length - 1 ? (
                      <svg
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        aria-hidden="true"
                        className="ml-2 h-5 w-5 flex-shrink-0 text-gray-300"
                      >
                        <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                      </svg>
                    ) : null}
                  </div>
                </li>
              ))}
            </ol>
          </nav>

          <div className="mt-4">
            <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              {product.name}
            </h1>
          </div>

          <section aria-labelledby="information-heading" className="mt-4">
            <h2 id="information-heading" className="sr-only">
              Product information
            </h2>

            <div className="flex items-center">
              <p className="text-lg text-gray-900 sm:text-xl">
                € {price} / week
              </p>
            </div>

            <div className="mt-4 space-y-6">
              <p className="text-base text-gray-500">{product.description}</p>
            </div>

            <div className="mt-6 flex items-center">
              <CheckIcon
                className="h-5 w-5 flex-shrink-0 text-green-500"
                aria-hidden="true"
              />
              <p className="ml-2 text-sm text-gray-500">Available to buy</p>
            </div>
            <div className="mt-6 w-full flex items-center">
              <ReactVisibilitySensor
                onChange={(visible) => {
                  if (visible) {
                    setCounterVisible(true);
                  }
                }}
                delayedCall
              >
                <div className="flex flex-row items-center justify-center text-gray-900 pt-2">
                  <h1>Only&nbsp;</h1>
                  <CountUp
                    start={0}
                    end={counterVisible ? calculatedSubscriptionsLeft : 0}
                    duration={3}
                    delay={1}
                  />
                  <h1 className="text-center ">&nbsp;left</h1>
                </div>
              </ReactVisibilitySensor>
              <CustomLinearProgress
                variant="determinate"
                value={(100 * count) / limit}
                color={"success"}
              />
            </div>
          </section>
        </div>

        {/* Product image */}
        <div className="mt-10 lg:col-start-2 lg:row-span-2 lg:mt-0 lg:self-center">
          <div className="aspect-w-1 aspect-h-1 overflow-hidden rounded-lg">
            <img
              src={product.imgUrl}
              alt={"Product Showcase"}
              className="h-full w-full object-cover object-center"
            />
          </div>
        </div>

        {/* Product form */}
        <div className="mt-10 lg:col-start-1 lg:row-start-2 lg:max-w-lg lg:self-start">
          <section aria-labelledby="options-heading">
            <h2 id="options-heading" className="sr-only">
              Product options
            </h2>

            <div className="flex flex-row items-center justify-around">
              <button
                onClick={() => {
                  setIngredientsModal(true);
                }}
                className="group inline-flex text-sm text-gray-500 hover:text-gray-700"
              >
                <span>Example of Meal</span>
                <QuestionMarkCircleIcon
                  className="ml-2 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                  aria-hidden="true"
                />
              </button>
              <button
                onClick={() => {
                  setMacroModal(true);
                }}
                className="group inline-flex text-sm text-gray-500 hover:text-gray-700"
              >
                <span>Macronutrients</span>
                <QuestionMarkCircleIcon
                  className="ml-2 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                  aria-hidden="true"
                />
              </button>
            </div>
            <div className="mt-10">
              <button
                onClick={async () => {
                  console.log("Buy");
                  await callStripe({ data: checkoutSessionProps });
                }}
                className="bg-gradient-to-r from-green-600 to-lime-600 shadow-sm hover:from-green-700 hover:to-lime-700 flex w-full items-center justify-center rounded-md border border-transparent  py-3 px-8 text-base font-medium text-white"
              >
                Buy
              </button>
            </div>
            <div className="mt-6 text-center">
              <div className="group inline-flex text-base font-medium">
                <ShieldCheckIcon
                  className="mr-2 h-6 w-6 flex-shrink-0 text-gray-400 "
                  aria-hidden="true"
                />
                <span className="text-gray-500 ">
                  Cancel Anytime, 100% Refund
                </span>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
