import { useContext, useState } from "react";
import { useSubscribeMail } from "../../hooks/useSubscibeMail";
import { Context } from "../../context";

export const EmailForm = () => {
  const { callApi } = useSubscribeMail();

  const globalState = useContext(Context);

  const mailSubscribedStateHandler = () => {
    globalState.updateState({ ...globalState, mailSubscribed: true });
  };

  const [email, setEmail] = useState("");
  return (
    <div className="mx-auto max-w-7xl  ">
      <div className="mt-4 sm:flex sm:max-w-md">
        <label htmlFor="email-address" className="sr-only">
          Email address
        </label>
        <input
          type="email"
          name="email-address"
          id="email-address"
          autoComplete="email"
          required
          className="w-full min-w-0 appearance-none rounded-md border border-gray-300 bg-white py-2 px-4 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:border-lime-500 focus:placeholder-gray-400 focus:outline-none focus:ring-lime-500"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
          <button
            onClick={async (e) => {
              await callApi({ data: { mail: email } });
              mailSubscribedStateHandler();
            }}
            className="flex w-full items-center justify-center rounded-md border border-transparent bg-gradient-to-r from-green-600 to-lime-600 bg-origin-border px-4 py-3 text-base font-medium text-white shadow-sm hover:from-green-700 hover:to-lime-700"
          >
            Subscribe
          </button>
        </div>
      </div>
    </div>
  );
};
