import { EmailForm } from "../emailForm";
import logo_no_text from "../../static/logoNoWriting.jpg";

const footerNavigation = {
  LearnMore: [
    { name: "Pricing", href: "#pricing" },
    { name: "Meal Plans", href: "#mealPlans" },
    { name: "Contact us", href: "mailto:info@dot-jar.com" },
    { name: "Newsletter", href: "#newsletter" },
  ],
  social: [],
};

const FooterLanding = () => {
  return (
    <footer className="bg-gray-50" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-7xl px-6 pt-16 pb-8 lg:px-8 lg:pt-24">
        <div className="md:grid md:grid-cols-2 md:gap-4 sm:flex-col sm:gap-8">
          <div className="sm:flex-col sm:gap-4 md:text-left sm:text-center">
            <h3 className="text-base font-medium text-gray-900">Learn More</h3>
            <ul  className="mt-4 space-y-4">
              {footerNavigation.LearnMore.map((item) => (
                <li key={item.name}>
                  <a
                    href={item.href}
                    className="text-base text-gray-500 hover:text-gray-900"
                  >
                    {item.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="mt-12 xl:mt-0 ">
            <h3 className="text-base font-medium text-gray-900">
              Enter your email:
            </h3>
            <p className="mt-4 text-base text-gray-500">
              Get updates on our progress:
            </p>

            <EmailForm />
          </div>
        </div>
        <div className="mt-12 border-t border-gray-200 pt-8 md:flex md:items-center md:justify-between lg:mt-16">
          <div className="flex space-x-6 md:order-2">
            <a href={"/"} className="text-gray-400 hover:text-gray-500">
              <span className="sr-only">{"Logo"}</span>
              <img className="h-12" src={logo_no_text} alt="" />
            </a>
          </div>
          <p className="mt-8 text-base text-gray-400 md:order-1 md:mt-0">
            &copy; 2024 MatteoLibrizzi, SARL. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default FooterLanding;
