import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Auth0ProviderWithHistory from "./auth/auth0ProviderWithHistory";
import LandingPage from "./pages/landing";
import LandingLayout from "./layouts/landingLayout";
import { ProductPage } from "./pages/productPage";
import ProductLayout from "./layouts/productLayout";
import { SuccessPage } from "./pages/success";

function App() {
  return (
    <BrowserRouter>
      <Auth0ProviderWithHistory>
        <Routes>
          <Route path="/" element={<LandingLayout />}>
            <Route index element={<LandingPage />} />
          </Route>
          <Route path="/product/:id" element={<ProductLayout />}>
            <Route index element={<ProductPage />} />
          </Route>
          <Route path="/success">
            <Route index element={<SuccessPage />} />
          </Route>
        </Routes>
      </Auth0ProviderWithHistory>
    </BrowserRouter>
  );
}

export default App;
