import { useParams } from "react-router-dom";
import Product from "../../components/product";
import { useProductInfo } from "../../hooks/useProductInfo";
import { useEffect } from "react";
import { CircularProgress } from "@mui/material";
import { useSubscribeInfo } from "../../hooks/useSubscribeInfo";

export const ProductPage = () => {
  const { id } = useParams();
  if (id !== "Standard" && id !== "Vegetarian" && id !== "Keto") {
    window.location.href = "/";
  }

  const { subscribersCount, subscribersLimit, weeklySusbcriptionPrice } =
    useSubscribeInfo();

  const { callApi, data } = useProductInfo();

  useEffect(() => {
    callApi({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const standardMealPlan =
    data && data.products
      ? data.products.find((product) => product.name === "Standard Meal Plan")
      : undefined;
  const veganMealPlan =
    data && data.products
      ? data.products.find((product) => product.name === "Vegetarian Meal Plan")
      : undefined;
  const ketoMealPlan =
    data && data.products
      ? data.products.find((product) => product.name === "Keto Meal Plan")
      : undefined;

  const product =
    id === "Keto"
      ? ketoMealPlan
      : id === "Vegetarian"
      ? veganMealPlan
      : standardMealPlan;

  return (
    <>
      {data && data.products && (
        <Product
          product={product}
          id={id}
          price={weeklySusbcriptionPrice}
          limit={subscribersLimit}
          count={subscribersCount}
        />
      )}
      {!(data && data.products) && (
        <div className="flex items-center justify-center">
          <CircularProgress />
        </div>
      )}
    </>
  );
};
