import { Popover } from "@headlessui/react";
import logo_no_text from "../../static/logoNoWriting.jpg";



const HeaderProduct = () => {
  return (
    <header>
      <Popover className="relative bg-white">
        <div className="mx-auto flex max-w-7xl items-center justify-between p-6  md:space-x-10 lg:px-8">
          <div className="flex justify-start lg:w-0 md:flex-1">
            <a href="/">
              <span className="sr-only">Dot Jar</span>
              <img className="h-12" src={logo_no_text} alt="" />
            </a>
          </div>
        </div>
      </Popover>
    </header>
  );
};
export default HeaderProduct;
