import { Outlet } from "react-router-dom";
import HeaderLanding from "../components/headerLanding";
import FooterLanding from "../components/footerLanding";
const LandingLayout = () => {
  return (
    <>
      <HeaderLanding />
      <div style={{paddingTop: 96}}>
        <Outlet />
      </div>
      <FooterLanding />
    </>
  );
};

export default LandingLayout;
