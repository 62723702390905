import { Tooltip, Typography } from "@mui/material";

export const Banner = ({ message, sx }) => {
  return (
    <Tooltip title="We haven't launched yet, we will only be able to serve 69 people in the beginning. Subscribe now to be one of them.">
      <Typography
        className="bg-gradient-to-r from-green-600 to-lime-600"
        sx={{
          ...sx,
        }}
        style={{
          position: "absolute",
          right: -70,
          top: 40,
          width: 280,
          transform: "rotate(45deg)",
          color: "white",
          padding: 8,
          textAlign: "center",
        }}
      >
        {message}
      </Typography>
    </Tooltip>
  );
};
