import logo_no_text from "../../static/logoNoWriting.jpg";

const FooterSimple = () => {
  return (
    <footer className="bg-gray-50" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-7xl px-6 pt-1 pb-8 lg:px-8 ">
        <div className="mt-12 border-t border-gray-200 pt-8 md:flex md:items-center md:justify-between lg:mt-16">
          <div className="flex space-x-6 md:order-2">
            <a href={"/"} className="text-gray-400 hover:text-gray-500">
              <span className="sr-only">{"Logo"}</span>
              <img className="h-12" src={logo_no_text} alt="" />
            </a>
          </div>
          <p className="mt-8 text-base text-gray-400 md:order-1 md:mt-0">
            &copy; 2024 MatteoLibrizzi, SARL. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default FooterSimple;
