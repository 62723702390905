import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { CheckCircleIcon } from "@heroicons/react/24/solid";

export const ThankYouModal = ({ open, setOpen, title, description }) => {
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        style={{
          position: "absolute",
          top: "40%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
        className="bg-gradient-to-r from-lime-600 to-green-600 rounded-md shadow-xl pt-4 p-6 flex justify-center items-center flex-col w-4/5 lg:w-2/5 sm:w-3/5"
      >
        <CheckCircleIcon
          className="h-10 w-10  text-white pb-2"
          aria-hidden="true"
        />
        <h1 className="text-center text-white font-bold text-lg pb-8">
          {title}
        </h1>
        <h3
          id="modal-modal-description text-center"
          className="text-gray-200"
          sx={{ mt: 2 }}
        >
          {description}
        </h3>
      </Box>
    </Modal>
  );
};
